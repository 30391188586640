<template>
<div>
	<CRow>
		<CCol sm="6" md="8">
        <CCard>
          <CCardHeader>
            <h4>Create New Admin</h4>      
          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

            <CForm>           

              <CInput
                label="Username"
                v-model="userName"
                placeholder ="Enter your Username"
                horizontal
              />

              <CInput
                label="Password"   
                v-model="password"           
                placeholder="Enter your password"
                type="password"
                horizontal
                autocomplete="current-password"
              />

              <CInput
                label="Email" 
                v-model="email"             
                placeholder="Enter your email"
                @change="onChangeEmail"
                type="email"
                horizontal
                autocomplete="email"
              />

              <CInput
                label="Phone Number"
                v-model="phoneNumber"
                type="number"
                placeholder ="Enter your Phone Number"
                horizontal
              />

              <CInput
                label="First Name"
                v-model="firstName"
                placeholder ="Enter your First Name"
                horizontal
              />

              <CInput
                label="Last Name"
                v-model="lastName"
                placeholder ="Enter your Last Name"
                horizontal
              />

              <!--b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >User Status</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="status" :options="optStatus"></b-form-select>
                   </b-col>                  
              </b-row><br/-->

               <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >User Group</label>
                  </b-col>   
                  <b-col sm="9"> 
                     <b-form-select v-model="groups" :options="optGroup"></b-form-select>
                   </b-col>                  
              </b-row><br/>

              <b-row>
                  <b-col sm="9"></b-col>   
                  <b-col sm="3"> 
                       <b-button-group>
                         <router-link to="/useradmin" tag="b-button">Cancel</router-link>
                         <b-button  v-on:click="CreateUser()" variant="primary">Submit</b-button>
                       </b-button-group>    
                  </b-col>                  
              </b-row><br/>   

            </CForm>
         
           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>


<script>
 import AuthService from '@/api/AuthService.js';
 import AdminService from '@/api/AdminService.js';

  export default {    
    data() {
      return {  
          userName:'',
          password:'', 
          email:'',
          validemail:false, 
          phoneNumber:'',
          firstName:'',
          lastName:'',
          status:'',
          groups:'admin',
          optStatus:[], 
          optGroup:[],
          seen:false,
          msg :'',
          color:''
          
        } ;      
      
    },
    created () {           
     
       this.getConfigList();  
       this.getUserGroupList(); 

    },
    methods: {

      CreateUser(){ 
         
        let params = {}; var groups = []; var validate = [];

        params['userName'] = this.userName;
        params['password'] = this.password;
        params['email'] = this.email;
        params['phoneNumber'] = this.phoneNumber;
        params['firstName'] = this.firstName;
        params['lastName'] = this.lastName;
        params['status'] = "ACTIVE";
        groups.push(this.groups);
        params['groups'] = groups;

        for (var key in params) {
          if(!params[key]) { validate.push(false); } else { validate.push(true); }
        }

        if(!validate.includes(false) && this.validemail){   //console.log(params); 
            if(localStorage.getItem('sa')==="true"){  
              this.createAdminUser(params); 
            } else { 
              this.msg   = 'Sorry, your role not allowed this action!'; 
              this.color = 'warning'; this.seen  = true;
            }
            
        } else {
           this.msg   = 'Data Not Complete or Invalid Email!'; 
           this.color = 'warning'; this.seen  = true;          
        } 

        setTimeout( () =>  this.seen=false , 5000);

      },

      onChangeEmail(){
          var email = this.email;
          var validate = this.checkEmail(email);
          this.validemail = validate;         
      },

      checkEmail(email) {
          var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
          if (!reg.test(email)) return false;
          return true;
      },      

      getConfigList: function() { 
          AuthService.getConfigList().then(resp => {         

            this.optStatus = resp.userStatus;
            
           }, error => {
            console.log('error');    
          });
      }, 

      getUserGroupList: function() { 
          AdminService.getUserGroupList().then(resp => {             
            var src = resp.payload; var temp =[];

            for (var key in src) {  
              var name = src[key].name;
              if(name!=="user") { temp.push(name); }
            }

            this.optGroup = temp;

           }, error => {
            console.log('error');    
          });
      },  

      createAdminUser: function(params) { 
          AdminService.createAdminUser(params).then(resp => {  

            //console.log(resp);        

            if(resp.status=='OK'){
                this.msg   = 'Create New Admin successfully'; 
                this.color = 'success';  this.seen  = true;

                this.userName='';
                this.password='';
                this.email='';
                this.phoneNumber='';
                this.firstName='';
                this.lastName='';

              } else {
                this.msg   = !resp.data.message ? "Create New Admin Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);  
              }
            
           }, error => {
            console.log('error');    
          });
      },        
      
    }
  }
</script>